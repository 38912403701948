import { createReducer, createActions } from 'reduxsauce'
// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Initial State
export const INITIAL_STATE = {
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  toggleLoading: false
}

const {Types, Creators} = createActions({
  contentWidth: ['contentWidth'],
  menuCollapsed: ['menuCollapsed'],
  menuHidden: ['menuHidden'],
  toggleLoading: ['toggleLoading']
})

const handleContentWidth = (state, { contentWidth }) => ({
  ...state,
  contentWidth: contentWidth ? contentWidth : null
})

const handleMenuCollapsed = (state, { menuCollapsed }) => ({
  ...state,
  menuCollapsed: menuCollapsed ? menuCollapsed : null
})

const handleMenuHidden = (state, { menuHidden }) => ({
  ...state,
  menuHidden: menuHidden ? menuHidden : null
})

const toggleLoading = (state, { toggleLoading }) => ({
  ...state,
  toggleLoading: toggleLoading ? toggleLoading : false
})

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CONTENT_WIDTH]: handleContentWidth,
  [Types.MENU_COLLAPSED]: handleMenuCollapsed,
  [Types.MENU_HIDDEN]: handleMenuHidden,
  [Types.TOGGLE_LOADING]: toggleLoading
})

export default Creators
