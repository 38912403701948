// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

//import { store } from './redux/storeConfig/store'
import store, { persistor, history } from './store'

// ** Intl, CASL & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utils/context/ThemeColors'
import { IntlProviderWrapper } from './i18n'

// ** Spinner (Splash Screen)
import Spinner from '@components/spinner/Fallback-spinner'

// ** Ripple Button
import '@components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import '@assets/fonts/feather/iconfont.css'
import '@assets/scss/core.scss'
import '@assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <PersistGate loading={null} persistor={persistor}>
                <Suspense fallback={<Spinner />}>
                    <ThemeContext>
                        <IntlProviderWrapper>
                            <LazyApp />
                            <ToastContainer newestOnTop />
                        </IntlProviderWrapper>
                    </ThemeContext>
                </Suspense>
            </PersistGate>
        </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
