import {
  takeEvery, put, call
} from 'redux-saga/effects'

import UserActions, { UserTypes } from '@store/reducers/User'
import handlePromise from '@utils/promise'
import UserService from '@services/User'
import { storage } from '@utils'

function* getUserProfile({ callback }) {
  try {
    const [error, response, responseData] = yield call(
      () => handlePromise(UserService.getUserProfile())
    )
    if (!response.ok) throw error

    storage.set('mozrest-fb-onboarding:userData', JSON.stringify(responseData))

    yield put(UserActions.updateUserStore({ profile: responseData }))
    if (callback) return callback()
  } catch (error) {
    if (callback) return callback(error)
  }
}

function* watchUpdateUser() {
  yield takeEvery(UserTypes.GET_USER_PROFILE, getUserProfile)
}

export default watchUpdateUser
