import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import { reducer as User } from './User'
import { reducer as Layout } from './Layout'

export default (history) => combineReducers({
  router: connectRouter(history),
  user: User,
  layout: Layout
})
