import api from '@configs/Api/Api'

const baseEndpoint = 'user-me'
const baseEndpoint2 = 'users'

export default {

  getUserProfile() {
    return api.get(baseEndpoint)
  },
  getUser(id) {
    return api.get(`${baseEndpoint2}/${id}`)
  },
  recoverPassword(params) {
    return api.post(`recover-password`, { ...params })
  }
}
