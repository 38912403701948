import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import omit from 'lodash/omit'
import reducers from './reducers'
import sagas from './saga'
import { INITIAL_STATE as layoutInitialState } from './reducers/Layout'

const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const middlewares = [routeMiddleware, sagaMiddleware]

const blacklistTransform = createTransform(
  (inboundState, key) => {
    if (key === 'layout') {
      //return omit( inboundState, ['menuCollapsed', 'menuHidden', 'contentWidth'] )
    }
    if (key === 'user') {
      return omit(inboundState, ['profile'])
    }
    return inboundState
  },
  (outboundState, key) => {
    if (key === 'layout') {
      return { ...layoutInitialState, ...outboundState }
    }
    return outboundState
  }
)

const persistConfig = {
  key: 'mozrest',
  whitelist: ['user', 'layout'],
  storage,
  transforms: [blacklistTransform]
}
const persistedReducer = persistReducer(persistConfig, reducers(history))

const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares))
)
sagaMiddleware.run(sagas)

const persistor = persistStore(store)

export default store
export { history, persistor, store }
